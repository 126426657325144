.contact--container{
    width: 100%;
    justify-self: flex-start;
    align-self: flex-end;
    display: grid;
    margin: 200px 0 50px 0;

    @media(max-width: $breakpoint-md){
        margin-left: 15%;
    }

    @media(max-width: $breakpoint-sm){
        width: 80%;
        margin-top: 150px;
    }

    h1{
        margin-bottom: 30px;
    }

    .contact--info{
        display: grid;
        grid-template-columns: 35% 40%;
        column-gap: 10%;
        color: $color-secondary;
        font-size: 17px;
        line-height: 25px;
        letter-spacing: 1px;
        margin-bottom: 30px;

        @media(max-width: $breakpoint-md){
            font-size: 15px;
            line-height: 20px;
        }

        @media(max-width: $breakpoint-sm){
            grid-template-columns: 100%;
            row-gap: 30px;
        }

        a{
            text-decoration: underline;
            transition: all .1s ease-in-out;
            cursor: pointer;
            color: $color-secondary;

            &:hover{
                border-color: #fff;
                color: #fff;
                text-shadow: 1px 1px 5px #fff;
            }
        }
    }

    .contact--form{
        width: 80%;

        @media(max-width: $breakpoint-sm){
            width: 90%;
        }
        label{
            opacity: 0;
            position: absolute;
        }

        input,
        textarea{
            width: 100%;
            background-color: $color-secondary;
            font-family: $font-primary;
            padding: 5px;
            margin-bottom: 15px;
            border-radius: 5px;
        }

        textarea{
            height: 100px;
            resize: none;
        }

        .contact-form--submit{
            display: flex;

            @media(max-width: $breakpoint-sm){
                display: grid;
                grid-template-columns: 100%;
            }
            
            .button{
                width: 100%;
                display: flex;
                justify-content: flex-start;

                @media(max-width: $breakpoint-sm){
                    grid-row: 1;
                    margin-bottom: 30px;
                }

                button{
                    height: 50px;
                    //width: 50%;
                    background-color: transparent;
                    font-family: $font-primary;
                    font-size: 30px;
                    color: $color-secondary;
                    border: none;
                    font-weight: 700;
                    //border: 1px solid $color-secondary;
                    padding: 10px;
                    transition: all .1s ease-in-out;
                    cursor: pointer;

                    @media(max-width: $breakpoint-md){
                        font-size: 23px;
                    }

                    &:hover{
                        //border-color: #fff;
                        color: #fff;
                        text-shadow: 1px 1px 5px #fff;
                        text-decoration: underline;
                        //box-shadow: 0px 0px 3px #fff;
                    }
                }
            }

        }
    }
}
