.hero--container{
    align-self: flex-end;
    justify-self: flex-end;
    margin-bottom: 90px;

    @media(max-width: $breakpoint-md){
        align-self: flex-end;
        margin-bottom: 30px;

    }

    @media(max-width: $breakpoint-sm){
        align-self: center;
        margin-top: 200px;
    }
    
    h2{
        margin-bottom: 100px;
        text-align: right;
        padding-right: 20px;

        @media(max-width: $breakpoint-sm){
            margin-bottom: 40px;
            padding-right: 50px;
        }
    }

    h1{
        white-space: nowrap;
        font-size: 4.7vw;

        @media(max-width: $breakpoint-md){
            font-size: 45px;
        }

        @media(max-width: $breakpoint-sm){
            font-size: 35px;
            white-space: unset;
            text-align: right;
            padding-right: 50px;
        }
    }
    
}