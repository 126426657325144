h1,
h2{
    color: $color-secondary;
    font-weight: 400;

    strong{
        font-weight: 700;
    }
}

h1{
    font-size: 4.2vw;

    @media(max-width: $breakpoint-md){
        font-size: 45px;
    }
    
    @media(max-width: $breakpoint-sm){
        font-size: 35px;
    }
}
h2{
    font-size: 2vw;

    @media(max-width: $breakpoint-md){
        font-size: 20px;
    }

    @media(max-width: $breakpoint-sm){
        font-size: 18px;
    }    
}