@import 'sass/variables';
@import 'sass/typography';
@import 'sass/navigation';
@import 'sass/layout';
@import 'sass/hero';
@import 'sass/about';
@import 'sass/work';
@import 'sass/contact';


html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-family: $font-primary;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}


body{
	background-color: $color-primary;
	overflow-x: hidden;
}

.attribute{
	opacity: 0;
	position: absolute;
	left: -200px;
}