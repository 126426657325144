.page--container{
    width: 100%;
    min-height: 100vh;
    display: grid;
    grid-template-columns: 30% 60% 10%;

    @media(max-width: $breakpoint-md){
        grid-template-columns: 90% 10%;
        //grid-template-rows: 80% 20%;
    }

    @media(max-width: $breakpoint-sm){
        grid-template-columns: 100%;
    }

    .email{
        align-self: flex-start;
        margin: 500px 0 90px 40px;
        font-family: $font-primary;
        font-size: 15px;
        color: $color-secondary;
        white-space: nowrap;

        @media(max-width: $breakpoint-md){
            grid-row: 2;
            grid-column: 1;
            margin-top: unset;
            align-self: flex-end;
            position: relative;
            bottom: 0;
        }

        @media(max-width: $breakpoint-sm){
            bottom: unset;
            margin-bottom: unset;
            padding: 30px 0;
        }

        a{
            text-decoration: none;
            color: $color-secondary;
            transition: all .1s ease-in-out;
            
            &:hover{ 
                color: #fff;
                text-shadow: 1px 1px 5px #fff;
                text-decoration: underline;
            }
        }
    }

    .socials{
        align-self: flex-start;
        justify-self: flex-end;
        width: 30px;
        position: relative;
        right: 10px;
        top: 300px;

        @media(max-width: $breakpoint-md){
            grid-row: 1;
            grid-column: 2;
        }

        @media(max-width: $breakpoint-sm){
            grid-row: 3;
            grid-column: 1;
            width: unset;
            right: unset;
            top: unset;
            justify-self: flex-start;
            align-self: flex-end;
            display: flex;
            left: 40px;
            padding-bottom: 30px;
        }

        .follow-me{
            color: $color-secondary;
            display: flex;
            transform: rotate(90deg);
            transform-origin: 0%;

            @media(max-width: $breakpoint-sm){
                transform: unset;
            }

            p{
                font-size: 15px;
                height: 40px;
                white-space: nowrap;

                &::after{
                    content: "";
                    display: block;
                    width: 50px;
                    height: .5px;
                    background-color: $color-secondary;
                    position: absolute;
                    left: 90px;
                    bottom: 30px;

                    @media(max-width: $breakpoint-sm){
                        position: relative;
                        margin-top: 22px;
                    }
                }
            }
        }

        a{
            display: inline-block;
            position: relative;
            top: 130px;

            &:first-of-type{
                margin-bottom: 15px;
            }

            @media(max-width: $breakpoint-sm){
                top: unset;
                left: 80px;
                
                &:first-of-type{
                    margin-bottom: unset;
                    margin-right: 15px;
                }
            }
            
            
            img{
                opacity: 50%;
                width: 25px;
                transition: all .1s ease-in-out;

                &:hover{
                    opacity: 1;
                    -webkit-filter: drop-shadow(1px 1px 5px #fff);
                    filter: drop-shadow(1px 1px 5px #fff);
                }
            }
        }
    }
}

//Scrollbar edit

/* width */
::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: $color-tertiary;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $color-primary;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #000;
  }

  html {
    overflow-y:scroll;
}

body{
    background-image: url(https://ik.imagekit.io/webzemi/Alex-Portfolio/v2/Untitled_design_-_2023-02-28T131602.446_8OYzXkwwmT.png?ik-sdk-version=javascript-1.4.3&updatedAt=1677608255046);
    padding-bottom: 20px;
    background-repeat: repeat-y;

    @media(max-width: $breakpoint-md){
        background-size: $breakpoint-md;
    }

    @media(max-width: $breakpoint-sm){
        background-size: $breakpoint-sm;
    }
}