.nav{
    /*
        Styling for screens wider than 1000px
    */
    background-color: #1c1819;
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    width: 100vw;
    height: 96px;
    display: flex;
    z-index: 1000;

    .logo{
        width: 50%;
        height: 100%;
        display: flex;
        align-items: center;
        margin-left: 5em;
        
        a{
            text-decoration: none;
            color: $color-secondary;
            font-size: 2.5vw;
            font-weight: 400;
            transition: all .2s ease-in-out;
            
            &:hover{ 
                color: #fff;
                text-shadow: 1px 1px 5px #fff;
            }

            span{ font-weight: 700; }
        }
    }

    nav{
        height: 100%;

    }

    .nav--menu{
        display: flex;
        gap: 36px;
        height: 100%;
        align-items: center;

        li{
            list-style: none;

            a{
                text-decoration: none;
                color: $color-secondary;
                font-size: 2vw;
                transition: all .1s ease-in-out;
                padding-bottom: 5px;

                &:hover{ 
                    color: #fff;
                    text-decoration: underline;
                    text-shadow: 1px 1px 5px #fff;
                }
            }
        }
    }

    /*
        Styling for screens under 1000px in width
    */
    @media(max-width: $breakpoint-md){
        position: fixed;

        .logo{
            a{font-size: 25px;}
        }

        .nav--menu{
            background-color: #1c1819;
            flex-direction: column;
            gap: 0;
            justify-content: center;


            li{
                border-top: 1px solid rgba($color-secondary, 0.95);
                width: 100%;
                margin: 0;

                &:nth-last-of-type(1){
                    border-bottom: 1px solid rgba($color-secondary, 0.95);
                }
                
                a{
                    font-size: 30px;
                }
            }
        }

        .b-a {
            transition: all .25s;
            content: "";
            position: absolute;
            right: 0;
            height: 6px;
            width: 30px;
            border-radius: 5px;
            background-color: rgba($color-secondary, 0.95);
        }
        
        .menu-collapsed{
            transition: all .25s;
            position: fixed;
            top: 10px;
            height: 100px;
            width: 100vw;
            z-index: 1;
            cursor: pointer;
        
            ul {
                transition: all 0s;
                position: fixed;
                right: -900px;
            }
        }
        
        .bar{
            @extend .b-a;
            position: fixed;
            right: 42px;
            top: 44px;
        
            &:before {
                @extend .b-a;
                top: -10px;
            }
        
            &:after {
                @extend .b-a;
                top: 10px;
            }
        }
        
        //menu expanded
        
        .b-a-expanded {
            transition: all .25s;
            top: -0px;
        }
        
        .menu-expanded{
            transition: all .25s;
            text-align: center;
            line-height: 200px;
            height: 100vh;
            width: 100%;
            border-radius: 0px;
            top: 0;
            right: 0;
            background-color: $color-primary;
            position: absolute;
            z-index: 100000;
            overflow: hidden;
        
            
            @media (max-width: $breakpoint-sm){
                line-height: 150px;
            }
        
            // ul {
            //     transition: all 0s;
            //     position: relative;
            //     right: 0;
            //     z-index: 2;
            // }
        
            // a {
            //     transition: all .15s;
            //     text-decoration: none;
            //     font-size: 2em;
            //     font-family: $font-primary;
            //     padding: 5px;
            //     color: $color-primary;
            //     display: block;
        
            //     &:hover {
            //         background-color: rgba($color-primary, 0.8);
            //         transition: all .15s;
            //         letter-spacing: 2px;
            //         color: rgba($color-secondary, 0.85);
            //     }
        
            //     @media(max-width: $breakpoint-sm){
            //         font-size: 1.5em;
            //     }
            //}
        
            .bar {
                background-color: transparent;
                transition: all .25s;
                z-index: 1000;
        
                &:before {
                    @extend .b-a-expanded;
                    transform: rotate(45deg);
                }
        
                &:after {
                    @extend .b-a-expanded;
                    transform: rotate(-45deg);
                }
            }
        
        }
    }
}