h1,
h2 {
  color: #D1C7C7;
  font-weight: 400;
}
h1 strong,
h2 strong {
  font-weight: 700;
}

h1 {
  font-size: 4.2vw;
}
@media (max-width: 1100px) {
  h1 {
    font-size: 45px;
  }
}
@media (max-width: 650px) {
  h1 {
    font-size: 35px;
  }
}

h2 {
  font-size: 2vw;
}
@media (max-width: 1100px) {
  h2 {
    font-size: 20px;
  }
}
@media (max-width: 650px) {
  h2 {
    font-size: 18px;
  }
}

.nav {
  /*
      Styling for screens wider than 1000px
  */
  background-color: #1c1819;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  width: 100vw;
  height: 96px;
  display: flex;
  z-index: 1000;
  /*
      Styling for screens under 1000px in width
  */
}
.nav .logo {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: 5em;
}
.nav .logo a {
  text-decoration: none;
  color: #D1C7C7;
  font-size: 2.5vw;
  font-weight: 400;
  transition: all 0.2s ease-in-out;
}
.nav .logo a:hover {
  color: #fff;
  text-shadow: 1px 1px 5px #fff;
}
.nav .logo a span {
  font-weight: 700;
}
.nav nav {
  height: 100%;
}
.nav .nav--menu {
  display: flex;
  gap: 36px;
  height: 100%;
  align-items: center;
}
.nav .nav--menu li {
  list-style: none;
}
.nav .nav--menu li a {
  text-decoration: none;
  color: #D1C7C7;
  font-size: 2vw;
  transition: all 0.1s ease-in-out;
  padding-bottom: 5px;
}
.nav .nav--menu li a:hover {
  color: #fff;
  text-decoration: underline;
  text-shadow: 1px 1px 5px #fff;
}
@media (max-width: 1100px) {
  .nav {
    position: fixed;
  }
  .nav .logo a {
    font-size: 25px;
  }
  .nav .nav--menu {
    background-color: #1c1819;
    flex-direction: column;
    gap: 0;
    justify-content: center;
  }
  .nav .nav--menu li {
    border-top: 1px solid rgba(209, 199, 199, 0.95);
    width: 100%;
    margin: 0;
  }
  .nav .nav--menu li:nth-last-of-type(1) {
    border-bottom: 1px solid rgba(209, 199, 199, 0.95);
  }
  .nav .nav--menu li a {
    font-size: 30px;
  }
  .nav .b-a, .nav .bar:after, .nav .bar:before, .nav .bar {
    transition: all 0.25s;
    content: "";
    position: absolute;
    right: 0;
    height: 6px;
    width: 30px;
    border-radius: 5px;
    background-color: rgba(209, 199, 199, 0.95);
  }
  .nav .menu-collapsed {
    transition: all 0.25s;
    position: fixed;
    top: 10px;
    height: 100px;
    width: 100vw;
    z-index: 1;
    cursor: pointer;
  }
  .nav .menu-collapsed ul {
    transition: all 0s;
    position: fixed;
    right: -900px;
  }
  .nav .bar {
    position: fixed;
    right: 42px;
    top: 44px;
  }
  .nav .bar:before {
    top: -10px;
  }
  .nav .bar:after {
    top: 10px;
  }
  .nav .b-a-expanded, .nav .menu-expanded .bar:after, .nav .menu-expanded .bar:before {
    transition: all 0.25s;
    top: 0px;
  }
  .nav .menu-expanded {
    transition: all 0.25s;
    text-align: center;
    line-height: 200px;
    height: 100vh;
    width: 100%;
    border-radius: 0px;
    top: 0;
    right: 0;
    background-color: #171414;
    position: absolute;
    z-index: 100000;
    overflow: hidden;
  }
}
@media (max-width: 1100px) and (max-width: 650px) {
  .nav .menu-expanded {
    line-height: 150px;
  }
}
@media (max-width: 1100px) {
  .nav .menu-expanded .bar {
    background-color: transparent;
    transition: all 0.25s;
    z-index: 1000;
  }
  .nav .menu-expanded .bar:before {
    transform: rotate(45deg);
  }
  .nav .menu-expanded .bar:after {
    transform: rotate(-45deg);
  }
}

.page--container {
  width: 100%;
  min-height: 100vh;
  display: grid;
  grid-template-columns: 30% 60% 10%;
}
@media (max-width: 1100px) {
  .page--container {
    grid-template-columns: 90% 10%;
  }
}
@media (max-width: 650px) {
  .page--container {
    grid-template-columns: 100%;
  }
}
.page--container .email {
  align-self: flex-start;
  margin: 500px 0 90px 40px;
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  color: #D1C7C7;
  white-space: nowrap;
}
@media (max-width: 1100px) {
  .page--container .email {
    grid-row: 2;
    grid-column: 1;
    margin-top: unset;
    align-self: flex-end;
    position: relative;
    bottom: 0;
  }
}
@media (max-width: 650px) {
  .page--container .email {
    bottom: unset;
    margin-bottom: unset;
    padding: 30px 0;
  }
}
.page--container .email a {
  text-decoration: none;
  color: #D1C7C7;
  transition: all 0.1s ease-in-out;
}
.page--container .email a:hover {
  color: #fff;
  text-shadow: 1px 1px 5px #fff;
  text-decoration: underline;
}
.page--container .socials {
  align-self: flex-start;
  justify-self: flex-end;
  width: 30px;
  position: relative;
  right: 10px;
  top: 300px;
}
@media (max-width: 1100px) {
  .page--container .socials {
    grid-row: 1;
    grid-column: 2;
  }
}
@media (max-width: 650px) {
  .page--container .socials {
    grid-row: 3;
    grid-column: 1;
    width: unset;
    right: unset;
    top: unset;
    justify-self: flex-start;
    align-self: flex-end;
    display: flex;
    left: 40px;
    padding-bottom: 30px;
  }
}
.page--container .socials .follow-me {
  color: #D1C7C7;
  display: flex;
  transform: rotate(90deg);
  transform-origin: 0%;
}
@media (max-width: 650px) {
  .page--container .socials .follow-me {
    transform: unset;
  }
}
.page--container .socials .follow-me p {
  font-size: 15px;
  height: 40px;
  white-space: nowrap;
}
.page--container .socials .follow-me p::after {
  content: "";
  display: block;
  width: 50px;
  height: 0.5px;
  background-color: #D1C7C7;
  position: absolute;
  left: 90px;
  bottom: 30px;
}
@media (max-width: 650px) {
  .page--container .socials .follow-me p::after {
    position: relative;
    margin-top: 22px;
  }
}
.page--container .socials a {
  display: inline-block;
  position: relative;
  top: 130px;
}
.page--container .socials a:first-of-type {
  margin-bottom: 15px;
}
@media (max-width: 650px) {
  .page--container .socials a {
    top: unset;
    left: 80px;
  }
  .page--container .socials a:first-of-type {
    margin-bottom: unset;
    margin-right: 15px;
  }
}
.page--container .socials a img {
  opacity: 50%;
  width: 25px;
  transition: all 0.1s ease-in-out;
}
.page--container .socials a img:hover {
  opacity: 1;
  -webkit-filter: drop-shadow(1px 1px 5px #fff);
  filter: drop-shadow(1px 1px 5px #fff);
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #655a5a;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #171414;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #000;
}

html {
  overflow-y: scroll;
}

body {
  background-image: url(https://ik.imagekit.io/webzemi/Alex-Portfolio/v2/Untitled_design_-_2023-02-28T131602.446_8OYzXkwwmT.png?ik-sdk-version=javascript-1.4.3&updatedAt=1677608255046);
  padding-bottom: 20px;
  background-repeat: repeat-y;
}
@media (max-width: 1100px) {
  body {
    background-size: 1100px;
  }
}
@media (max-width: 650px) {
  body {
    background-size: 650px;
  }
}

.hero--container {
  align-self: flex-end;
  justify-self: flex-end;
  margin-bottom: 90px;
}
@media (max-width: 1100px) {
  .hero--container {
    align-self: flex-end;
    margin-bottom: 30px;
  }
}
@media (max-width: 650px) {
  .hero--container {
    align-self: center;
    margin-top: 200px;
  }
}
.hero--container h2 {
  margin-bottom: 100px;
  text-align: right;
  padding-right: 20px;
}
@media (max-width: 650px) {
  .hero--container h2 {
    margin-bottom: 40px;
    padding-right: 50px;
  }
}
.hero--container h1 {
  white-space: nowrap;
  font-size: 4.7vw;
}
@media (max-width: 1100px) {
  .hero--container h1 {
    font-size: 45px;
  }
}
@media (max-width: 650px) {
  .hero--container h1 {
    font-size: 35px;
    white-space: unset;
    text-align: right;
    padding-right: 50px;
  }
}

.about--container {
  width: 100%;
  justify-self: flex-start;
  align-self: center;
  margin: 200px 0 50px 0;
}
@media (max-width: 1100px) {
  .about--container {
    width: unset;
    max-width: 80%;
    justify-self: unset;
    margin-bottom: 100px;
    align-self: unset;
    margin-top: 250px;
    margin-left: 15%;
    padding-right: 20px;
  }
}
@media (max-width: 650px) {
  .about--container {
    margin-top: 150px;
    margin-bottom: 50px;
  }
}
.about--container p {
  color: #D1C7C7;
  font-size: 17px;
  line-height: 25px;
  letter-spacing: 1px;
}
.about--container p:first-of-type {
  margin: 50px 0 30px 0;
}
@media (max-width: 650px) {
  .about--container p {
    font-size: 15px;
  }
}

.work--container {
  width: 100%;
  justify-self: flex-start;
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  margin: 200px 0 50px 0;
}
@media (max-width: 1100px) {
  .work--container {
    width: unset;
    justify-self: center;
    align-self: unset;
    margin: 250px 0 100px 150px;
  }
}
@media (max-width: 650px) {
  .work--container {
    width: 100%;
    margin-top: 150px;
    margin-left: 0;
    justify-self: unset;
  }
}
.work--container h1 {
  padding-bottom: 40px;
}
@media (max-width: 650px) {
  .work--container h1 {
    margin-left: 15%;
  }
}
.work--container .work-item {
  border: 0.5px solid rgba(209, 199, 199, 0.2);
  width: 300px;
  min-width: 300px;
  background-color: #171414;
  padding: 15px;
  padding-bottom: 30px;
  color: #D1C7C7;
  margin-bottom: 70px;
}
.work--container .work-item--container {
  width: 100%;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-auto-rows: max-content;
}
@media (max-width: 1100px) {
  .work--container .work-item--container {
    grid-template-columns: 100%;
    row-gap: 50px;
  }
}
@media (max-width: 1100px) {
  .work--container .work-item {
    width: unset;
    min-width: 400px;
  }
}
@media (max-width: 650px) {
  .work--container .work-item {
    min-width: unset;
    width: 80%;
    justify-self: center;
  }
}
.work--container .work-item img {
  width: 100%;
  border: 0.5px solid rgba(209, 199, 199, 0.2);
  margin-bottom: 30px;
}
.work--container .work-item h3 {
  font-size: 30px;
}
@media (max-width: 1100px) {
  .work--container .work-item h3 {
    margin-left: 20px;
  }
}
.work--container .work-item h4 {
  font-weight: 400;
  margin-bottom: 20px;
  font-size: 18px;
  letter-spacing: 1px;
}
@media (max-width: 1100px) {
  .work--container .work-item h4 {
    margin-left: 20px;
  }
}
.work--container .work-item p {
  margin-bottom: 10px;
  letter-spacing: 1px;
}
@media (max-width: 1100px) {
  .work--container .work-item p {
    margin-left: 20px;
  }
}
.work--container .work-item--list {
  display: grid;
  grid-template-columns: 47% 47%;
  gap: 6%;
}
@media (max-width: 1100px) {
  .work--container .work-item--list {
    margin-left: 20px;
  }
}
.work--container .work-item--list li {
  font-size: 14px;
  margin-left: 20px;
  margin-bottom: 5px;
  font-style: italic;
  letter-spacing: 1px;
}
.work--container .work-item--link {
  margin-top: 20px;
}
@media (max-width: 1100px) {
  .work--container .work-item--link {
    margin-left: 20px;
  }
}
.work--container .work-item--link a {
  text-decoration: none;
  color: #D1C7C7;
  transition: all 0.1s ease-in-out;
  font-size: 17px;
  letter-spacing: 1px;
}
.work--container .work-item--link a:hover {
  color: #fff;
  text-shadow: 1px 1px 5px #fff;
  text-decoration: underline;
}

.contact--container {
  width: 100%;
  justify-self: flex-start;
  align-self: flex-end;
  display: grid;
  margin: 200px 0 50px 0;
}
@media (max-width: 1100px) {
  .contact--container {
    margin-left: 15%;
  }
}
@media (max-width: 650px) {
  .contact--container {
    width: 80%;
    margin-top: 150px;
  }
}
.contact--container h1 {
  margin-bottom: 30px;
}
.contact--container .contact--info {
  display: grid;
  grid-template-columns: 35% 40%;
  column-gap: 10%;
  color: #D1C7C7;
  font-size: 17px;
  line-height: 25px;
  letter-spacing: 1px;
  margin-bottom: 30px;
}
@media (max-width: 1100px) {
  .contact--container .contact--info {
    font-size: 15px;
    line-height: 20px;
  }
}
@media (max-width: 650px) {
  .contact--container .contact--info {
    grid-template-columns: 100%;
    row-gap: 30px;
  }
}
.contact--container .contact--info a {
  text-decoration: underline;
  transition: all 0.1s ease-in-out;
  cursor: pointer;
  color: #D1C7C7;
}
.contact--container .contact--info a:hover {
  border-color: #fff;
  color: #fff;
  text-shadow: 1px 1px 5px #fff;
}
.contact--container .contact--form {
  width: 80%;
}
@media (max-width: 650px) {
  .contact--container .contact--form {
    width: 90%;
  }
}
.contact--container .contact--form label {
  opacity: 0;
  position: absolute;
}
.contact--container .contact--form input,
.contact--container .contact--form textarea {
  width: 100%;
  background-color: #D1C7C7;
  font-family: "Montserrat", sans-serif;
  padding: 5px;
  margin-bottom: 15px;
  border-radius: 5px;
}
.contact--container .contact--form textarea {
  height: 100px;
  resize: none;
}
.contact--container .contact--form .contact-form--submit {
  display: flex;
}
@media (max-width: 650px) {
  .contact--container .contact--form .contact-form--submit {
    display: grid;
    grid-template-columns: 100%;
  }
}
.contact--container .contact--form .contact-form--submit .button {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
@media (max-width: 650px) {
  .contact--container .contact--form .contact-form--submit .button {
    grid-row: 1;
    margin-bottom: 30px;
  }
}
.contact--container .contact--form .contact-form--submit .button button {
  height: 50px;
  background-color: transparent;
  font-family: "Montserrat", sans-serif;
  font-size: 30px;
  color: #D1C7C7;
  border: none;
  font-weight: 700;
  padding: 10px;
  transition: all 0.1s ease-in-out;
  cursor: pointer;
}
@media (max-width: 1100px) {
  .contact--container .contact--form .contact-form--submit .button button {
    font-size: 23px;
  }
}
.contact--container .contact--form .contact-form--submit .button button:hover {
  color: #fff;
  text-shadow: 1px 1px 5px #fff;
  text-decoration: underline;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-family: "Montserrat", sans-serif;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  background-color: #171414;
  overflow-x: hidden;
}

.attribute {
  opacity: 0;
  position: absolute;
  left: -200px;
}

