.about--container{
    width: 100%;
    justify-self: flex-start;
    align-self: center;
    margin: 200px 0 50px 0;

    @media(max-width: $breakpoint-md){
        width: unset;
        max-width: 80%;
        justify-self: unset;
        margin-bottom: 100px;
        align-self: unset;
        margin-top: 250px;
        margin-left: 15%;
        padding-right: 20px;
    }

    @media(max-width: $breakpoint-sm){
        margin-top: 150px;
        margin-bottom: 50px;
        
    }

    h1{

    }

    p{
        color: $color-secondary;
        font-size: 17px;
        line-height: 25px;
        letter-spacing: 1px;
        
        &:first-of-type{
            margin: 50px 0 30px 0;
        }

        @media(max-width: $breakpoint-sm){
            font-size: 15px;
        }
    }
}