.work--container{
    width: 100%;
    justify-self: flex-start;
    display: flex;
    flex-direction: column;
    align-self: flex-end;
    margin: 200px 0 50px 0;

    @media(max-width: $breakpoint-md){
        width: unset;
        justify-self: center;
        align-self: unset;
        margin: 250px 0 100px 150px;
    }

    @media(max-width: $breakpoint-sm){
        width: 100%;
        margin-top: 150px;
        margin-left: 0;
        justify-self: unset;
        //padding-bottom: 100px;
    }

    h1{
        padding-bottom: 40px;

        @media(max-width: $breakpoint-sm){
            margin-left: 15%;
        }
    }

    .work-item{
        &--container{
            width: 100%;
            display: grid;
            grid-template-columns: 50% 50%;
            grid-auto-rows: max-content;

            @media(max-width: $breakpoint-md){
                grid-template-columns: 100%;
                row-gap: 50px;
            }
        }

        border: .5px solid rgba($color-secondary, .2);
        width: 300px;
        min-width: 300px;
        background-color: $color-primary;
        padding: 15px;
        padding-bottom: 30px;
        color: $color-secondary;

        @media(max-width: $breakpoint-md){
            width: unset;
            min-width: 400px;
        }

        @media(max-width: $breakpoint-sm){
            min-width: unset;
            width: 80%;
            justify-self: center;
        }

        img{
            width: 100%;
            border: .5px solid rgba($color-secondary, .2);
            margin-bottom: 30px;
        }

        h3{
            font-size: 30px;

            @media(max-width: $breakpoint-md){
                margin-left:20px;
            }
        }

        h4{
            font-weight: 400;
            margin-bottom: 20px;
            font-size: 18px;
            letter-spacing: 1px;

            @media(max-width: $breakpoint-md){
                margin-left:20px;
            }

        }

        p{
            margin-bottom: 10px;
            letter-spacing: 1px;

            @media(max-width: $breakpoint-md){
                margin-left:20px;
            }
        }

        &--list{
            display: grid;
            grid-template-columns: 47% 47%;
            gap: 6%;

            @media(max-width: $breakpoint-md){
                margin-left:20px;
            }

            li{
                font-size: 14px;
                margin-left: 20px;
                margin-bottom: 5px;
                font-style: italic;
                letter-spacing: 1px;
            }
        }

        &--link{
            margin-top: 20px;

            @media(max-width: $breakpoint-md){
                margin-left:20px;
            }

            a{
                text-decoration: none;
                color: $color-secondary;
                transition: all .1s ease-in-out;
                font-size: 17px;
                letter-spacing: 1px;
                
                &:hover{ 
                    color: #fff;
                    text-shadow: 1px 1px 5px #fff;
                    text-decoration: underline;
                }

            }
        }
    }
}

